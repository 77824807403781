
import giftCardService from '@/services/giftCards/giftCardService';
import { Component, Vue, Watch } from 'vue-property-decorator';
import QRCodeReader from '@/components/QRCodeReader.vue';

@Component({
  components: { QRCodeReader },
})
export default class CardCredited extends Vue {
  public cardValidityStartDate = null as any;
  public isScannerDisplayed = false;
  public cardValidityEndDate = null as any;
  public cardCreditValue: null | number = null;
  public cardCreditError = '';
  public category = '' as any;
  public nameClient = '';
  public selectedValidityStartDate = false;
  public selectedValidityEndDate = false;
  public wantToWriteCode = false;
  public codeCard = '';
  public error = '';

  get checkIfAllInputsAreFilled(): boolean {
    return (
      this.cardCreditValue !== null
      && typeof this.cardCreditValue === 'number'
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      && this.cardCreditValue > 0
      && this.category !== undefined
      && this.nameClient !== ''
      && this.cardValidityStartDate !== null
      && this.cardValidityEndDate !== null
      && this.cardCreditValueValidationMessage === ''
    );
  }

  // add uppercase to the first letter of the string
  @Watch('nameClient')
  onNameClientChanged(): void {
    this.nameClient = this.nameClient.charAt(0).toUpperCase() + this.nameClient.slice(1);
  }

  openContactMail(): void {
    const to = process.env.VUE_APP_MAIL_CONTACT;
    window.location.href = `mailto:${to}?subject=Problème%20carte%20CKDO`;
  }

  clearDateValidityStartDate(): void {
    this.cardValidityStartDate = new Date();
  }

  clearDateValidityEndDate(): void {
    this.cardValidityEndDate = null;
  }

  formatListCategorieClient(value: string): string {
    if (value === 'Collectivite') return 'Collectivité';
    if (value === 'Entreprise') return 'Entreprise';
    if (value === 'Particulier') return 'Particulier';
    if (value === 'Comite_entreprise') return 'Comité d\'entreprise';
    return '';
  }

  async listCategorieClient(): Promise<void> {
    try {
      const { data } = await giftCardService.getListClientCategoriesValues();
      this.listCategorieClient = data;
      // const { data: categories } = await giftCardService.getListClientCategories();
      this.category = data[0].id;
    } catch (error: any) {
      const { data } = error.response;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      console.log(message);
    }
  }

  created(): void {
    this.listCategorieClient();
  }

  // async checkValidityCard(): Promise<boolean> {
  //   const code = this.codeCard.replace('-', '');
  //   try {
  //     await giftCardService.checkValidity(code);
  //     return true;
  //   } catch (error: any) {
  //     const { data } = error.response;
  //     this.error = Array.isArray(data.message) ? data.message[0] : data.message;
  //   }
  //   return false;
  // }

  get cardCreditValueValidationMessage(): string {
    if (this.cardCreditValue === null) {
      return '';
    }
    if (Number.isNaN(this.cardCreditValue)) {
      return 'Veuillez saisir un nombre valide.';
    }
    if (this.cardCreditValue <= 0) {
      return 'Veuillez saisir un montant supérieur à 0.';
    }
    if (this.cardCreditValue.toString().includes('.')) {
      const splittedValue = this.cardCreditValue.toString().split('.');
      if (splittedValue[1].length > 2) {
        return 'Veuillez saisir un montant avec 2 chiffres après la virgule.';
      }
    }
    return '';
  }

  @Watch('cardCreditValue')
  onCardCreditValueChange(): void {
    if (this.cardCreditValue !== null && this.cardCreditValue > 0 && this.cardCreditValue < 250) {
      this.cardCreditError = '';
    } else if (this.cardCreditValue !== null && this.cardCreditValue < 0) {
      this.cardCreditValue = 0;
    } else if (this.cardCreditValue !== null && this.cardCreditValue > 250) {
      this.cardCreditError = 'Le montant maximum est de 250 €';
      this.cardCreditValue = 250;
    }
  }

  async onDecode(code: string): Promise<void> {
    try {
      // const checkCard = await this.checkValidityCard();
      // if (!checkCard) return;
      this.codeCard = code;
      this.isScannerDisplayed = false;
      await giftCardService.creditGiftCard(code, {
        credit: this.cardCreditValue,
        clientCategory: this.category,
        clientName: this.nameClient,
        validityStartDate: this.cardValidityStartDate,
        validityEndDate: this.cardValidityEndDate as any,
      });
      const card = {
        code: this.codeCard,
        credit: this.cardCreditValue,
        clientCategory: this.category,
        clientName: this.nameClient,
        validityStartDate: this.cardValidityStartDate,
        validityEndDate: this.cardValidityEndDate,
      };
      await this.$store.dispatch('giftCardModule/setCard', card);
      this.$router.push({ name: 'CardCreditedResult', query: { result: 'success' } });
    } catch (error: any) {
      this.$router.push({ name: 'CardCreditedResult', query: { result: 'error' } });
      const { data } = error.response;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      console.log(message);
    }
  }

  async submitCreditCard(): Promise<void> {
    const code = this.codeCard.replace('-', '');
    try {
      if (this.cardValidityEndDate === null) return;
      // const checkCard = await this.checkValidityCard();
      // if (!checkCard) return;
      await giftCardService.creditGiftCard(code, {
        credit: this.cardCreditValue,
        clientCategory: this.category,
        clientName: this.nameClient,
        validityStartDate: this.cardValidityStartDate,
        validityEndDate: this.cardValidityEndDate as any,
      });
      const card = {
        code: this.codeCard,
        credit: this.cardCreditValue,
        clientCategory: this.category,
        clientName: this.nameClient,
        validityStartDate: this.cardValidityStartDate,
        validityEndDate: this.cardValidityEndDate,
      };
      await this.$store.dispatch('giftCardModule/setCard', card);
      this.$router.push({ name: 'CardCreditedResult', query: { result: 'success' } });
      // put on store data card
    } catch (error: any) {
      this.$router.push({ name: 'CardCreditedResult', query: { result: 'error' } });
      const { data } = error.response;
      const message = Array.isArray(data.message) ? data.message[0] : data.message;
      this.$buefy.toast.open({
        message,
        type: 'is-danger',
        duration: 3000,
      });
    }
    // try catch if is in error router.push query result = error
  }
}
